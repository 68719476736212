import { Outlet, useLocation } from 'react-router-dom'
import { Suspense, useEffect } from 'react'
import { Header } from '../layout/Header.tsx'
import styled from 'styled-components'
import { layoutContainers } from '../assets/style/mixins.ts'
import { OptimizedPackageProvider } from './OptimizedPackageState.tsx'
import { InstallationRequestProvider } from '@/installationRequest/InstallationRequestState.tsx'
import { ErrorBoundary } from '@sentry/react'
import ErrorView from '@/uiComponents/ErrorView.tsx'
import MobileModal from '@/MobileModal.tsx'

const Main = styled.main`
  ${layoutContainers.largeContainer};
  min-width: 1280px;
  min-height: calc(100vh - var(--header-height));
  min-height: calc(100dvh - var(--header-height));
  padding-top: 102px;
`
export function SalesAssistantLayout() {
  const location = useLocation()

  useEffect(() => {
    document.title = 'Build your Ava package'
  }, [location])

  return (
    <>
      <Header />
      <Main>
        <ErrorBoundary fallback={<ErrorView />}>
          <MobileModal />
          <OptimizedPackageProvider>
            <InstallationRequestProvider>
              <Suspense fallback={<div>Loading...</div>}>
                <Outlet />
              </Suspense>
            </InstallationRequestProvider>
          </OptimizedPackageProvider>
        </ErrorBoundary>
      </Main>
    </>
  )
}
