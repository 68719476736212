import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { PathConstants } from '../pathConstant'
import { createContext, useCallback, useMemo, useState } from 'react'
import { RegisterSessionRoot } from './RegisterSessionRoot'
import { ProfileProvider } from '@/profile/ProfileState'

type PostLoginContextType = {
  appState?: AppState
}
export const PostLoginContext = createContext<PostLoginContextType>({})

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [appState, setAppState] = useState<AppState | undefined>(undefined)
  const handleRedirect = useCallback((appState?: AppState) => {
    setAppState(appState)
  }, [])

  const contextValue = useMemo(() => ({ appState }), [appState])

  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/${PathConstants.POST_LOGIN}`,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      }}
      onRedirectCallback={handleRedirect}
    >
      <PostLoginContext.Provider value={contextValue}>
        <ProfileProvider>
          <RegisterSessionRoot>{children}</RegisterSessionRoot>
        </ProfileProvider>
      </PostLoginContext.Provider>
    </Auth0Provider>
  )
}

export default AuthProvider
