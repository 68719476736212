import styled from 'styled-components'
import Logo from '@/assets/icons/logo.svg?react'
import { useState } from 'react'

const ModalOverlay = styled.div<{ $isVisible: boolean }>`
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  background-color: #00000080;
  inset: 0;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition:
    opacity 0.3s,
    visibility 0.3s;
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};

  @media (min-width: 769px) {
    display: none;
  }
`

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: var(--radius-md);
  background: var(--base-white);
  box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
  text-align: center;
`

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 10px;
  border: none;
  background: none;
  color: var(--base-black);
  font-size: var(--font-text-3xl);
`

const StyledLogo = styled(Logo)`
  width: 100px;
  height: auto;
  margin-top: 20px;
`

const StyledParagraph = styled.p`
  margin: 20px 0;
  color: var(--gray-dark-mode-700);
  font-size: var(--font-text-md);
`

const MobileModal = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true)

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      setIsVisible(false)
    }
  }

  return (
    <ModalOverlay $isVisible={isVisible} onClick={handleOverlayClick}>
      <ModalContent>
        <CloseButton
          onClick={() => {
            setIsVisible(false)
          }}
          aria-label="Close modal"
        >
          &times;
        </CloseButton>
        <StyledParagraph>For better experience, please use Ava Advisor on a desktop. Thank you.</StyledParagraph>
        <StyledLogo />
      </ModalContent>
    </ModalOverlay>
  )
}

export default MobileModal
